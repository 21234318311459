

















































import { SfButton, SfSearchBar } from '@storefront-ui/vue';
import {
  defineComponent,
  ref,
  watch,
  useRoute,
  useRouter,
  useContext,
  onMounted,
} from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';
import { clickOutside } from '~/composables/directives/click-outside/click-outside-directive';
import SvgImage from '~/components/General/SvgImage.vue';
import { useFacet, useCategorySearch } from '@gemini-vsf/composables';
import { useTranslation } from '~/composables';

export default defineComponent({
  name: 'SearchBar',
  components: {
    SfSearchBar,
    SfButton,
    SvgImage,
  },
  directives: { clickOutside },
  props: {
    isSearchOpen: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 12,
    },
    minTermLen: {
      type: Number,
      default: 3,
    },
  },
  emits: [
    'set-is-open',
    'set-search-results',
    'set-search-word',
    'set-not-available-product',
  ],
  setup(props, { emit }) {
    const term = ref('');
    const route = useRoute();
    const router = useRouter();
    const { app } = useContext();
    const searchLoader = ref(false);
    const { $gt } = useTranslation('translations');

    // TODO implement gemini search
    // const { getProductList } = useProduct();

    const {
      result: searchResult,
      search: productsSearch,
      // loading: productsLoading,
    } = useFacet('AppHeader:Products');
    const { result: categories, search: categoriesSearch } = useCategorySearch(
      'AppHeader:Categories'
    );

    const showSearch = () => {
      if (!props.isSearchOpen) {
        emit('set-is-open', true);
        if (document) {
          document.documentElement.classList.add('search-no-scroll');
        }
      }
    };

    const hideSearch = () => {
      if (props.isSearchOpen) {
        emit('set-is-open', false);
        emit('set-search-results', null);
        emit('set-search-word', '');
        if (document) {
          document.documentElement.classList.remove('search-no-scroll');
        }
      }
    };

    const toggleSearch = () => {
      if (props.isSearchOpen) {
        hideSearch();
      } else {
        showSearch();
      }
    };

    const closeSearch = (event: MouseEvent) => {
      if (document) {
        const searchResultsEl = document.querySelector('.search');
        const closeTriggerElement = event.target as HTMLElement;

        if (!searchResultsEl?.contains(closeTriggerElement)) {
          const bodyEle = document.querySelectorAll('body');
          bodyEle.length > 0 && bodyEle[0].classList.remove('search-box');
          hideSearch();
          term.value = '';
        }
      } else {
        hideSearch();
        term.value = '';
      }
    };

    const rawHandleSearch = async (searchTerm: string) => {
      term.value = searchTerm;
      if (term.value.length < props.minTermLen) {
        searchLoader.value = false;
        emit('set-search-results', null);
        emit('set-search-word', '');
      }

      await productsSearch({
        itemsPerPage: 12,
        term: term.value,
        customQuery: {
          products: 'searchProductListCustom',
        },
      });

      let improvedMatches = [];
      try {
        improvedMatches = searchResult.value?.data?.items?.filter((item) =>
          item.list_sku_variants.some((v) =>
            v.toLowerCase().startsWith(term.value)
          )
        );
      } catch (error) {
        console.error(error);
        improvedMatches = [];
      }

      emit(
        'set-search-results',
        improvedMatches.length > 0
          ? improvedMatches
          : searchResult.value?.data?.items
      );
      emit('set-search-word', term.value);
    };
    const debouncedHandleSearch = debounce(rawHandleSearch, 1000);

    const handleKeydownEnter = (searchTerm: string) => {
      debouncedHandleSearch.cancel();
      const inputValue = searchTerm.toLowerCase();
      if (inputValue !== '') {
        router.push(app.localeRoute(`/search-result/?keyword=${inputValue}`));
      }
    };

    watch(route, () => {
      hideSearch();
      term.value = '';
    });

    onMounted(() => {
      // listen for "search" event on input#search
      const searchInput = document.querySelector('input#search');
      if (searchInput) {
        searchInput.addEventListener('search', (event) => {
          const inputValue = (event.target as HTMLInputElement).value;
          if (inputValue === '') {
            hideSearch();
            debouncedHandleSearch(inputValue);
          }
        });
        searchInput.addEventListener('input', (event) => {
          emit(
            'set-not-available-product',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            !!event?.detail?.productNotAvailable
          );
        });
      }
    });

    return {
      closeSearch,
      showSearch,
      hideSearch,
      toggleSearch,
      rawHandleSearch,
      debouncedHandleSearch,
      handleKeydownEnter,
      term,
      $gt,
    };
  },
});
